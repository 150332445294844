import Lottie from "lottie-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./NotFound.scss";
import notFoundJSON from "./notFoundJSON.json";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="not-found__container">
      <Lottie
        animationData={notFoundJSON}
        className="not-found__animation"
        loop={true}
      />
      <button className="not-found__button" onClick={() => navigate(-1)}>
        Back
      </button>
    </div>
  );
};

export default NotFound;
