import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { EffectFade, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Facility.scss";

const Facility = () => {
  return (
    <section className="facility__container">
      <h1>Our Facilities</h1>
      <Swiper
        className="facility__swiper"
        effect={"fade"}
        grabCursor={true}
        loop={true}
        modules={[EffectFade, Navigation, Pagination]}
        navigation={{
          nextEl: ".facility__navigation--next",
          prevEl: ".facility__navigation--prev",
        }}
        pagination={{
          clickable: true,
        }}
      >
        <SwiperSlide className="facility__swiper-slide">
          <picture>
            <source
              media="(min-width:640px)"
              srcSet="https://images.alphacoders.com/129/1294486.png"
            />
            <img alt="" src="https://artfiles.alphacoders.com/152/152722.jpg" />
          </picture>
        </SwiperSlide>
        <SwiperSlide className="facility__swiper-slide">
          <picture>
            <source
              media="(min-width:640px)"
              srcSet="https://images.alphacoders.com/781/781392.png"
            />
            <img alt="" src="https://images4.alphacoders.com/830/830210.png" />
          </picture>
        </SwiperSlide>
        <SwiperSlide className="facility__swiper-slide">
          <picture>
            <source
              media="(min-width:640px)"
              srcSet="https://images4.alphacoders.com/105/1056216.png"
            />
            <img alt="" src="https://images4.alphacoders.com/125/1250516.jpg" />
          </picture>
        </SwiperSlide>
        <SwiperSlide className="facility__swiper-slide">
          <picture>
            <source
              media="(min-width:640px)"
              srcSet="https://images7.alphacoders.com/115/1155199.jpg"
            />
            <img alt="" src="https://images2.alphacoders.com/556/556384.png" />
          </picture>
        </SwiperSlide>
        <button className="facility__navigation facility__navigation--next">
          <FontAwesomeIcon icon={faCaretRight} />
        </button>
        <button className="facility__navigation facility__navigation--prev">
          <FontAwesomeIcon icon={faCaretLeft} />
        </button>
      </Swiper>
    </section>
  );
};

export default Facility;
