import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import Lottie from "lottie-react";
import React from "react";
import daycare from "../../../assets/jsons/daycare.json";
import preschool from "../../../assets/jsons/preschool.json";
import "./Milestone.scss";

const Milestone = () => {
  useGSAP(() => {
    gsap.utils.toArray(".milestone__container").forEach((section) => {
      let content = section.querySelector(".milestone__content");

      let tl = gsap.timeline({
        defaults: { ease: "none" },
        scrollTrigger: {
          anticipatePin: 1,
          end: () => "+=" + content.offsetWidth,
          scrub: 0.5,
          start: "center center",
          pin: true,
          trigger: section,
        },
      });

      tl.fromTo(
        section.querySelector(".milestone__content"),
        {
          xPercent: 0,
          x: 0,
        },
        { xPercent: -100 }
      );
    });
  });

  return (
    <section className="milestone__container">
      <h1>Milestone</h1>
      <div className="milestone__content">
        <div className="milestone__item">
          <div className="milestone__item-title milestone__item-title--top">
            <h2>2012</h2>
            <p>Didirikan pada tanggal 1 Januari 2012</p>
          </div>
          <Lottie
            animationData={preschool}
            className="milestone__animation"
            loop={true}
          />
        </div>
        <div className="milestone__item">
          <div className="milestone__item-title milestone__item-title--bottom">
            <p>Didirikan pada tanggal 1 Januari 2012</p>
            <h2>2012</h2>
          </div>
          <div className="milestone__checkpoint"></div>
        </div>
        <div className="milestone__item">
          <div className="milestone__item-title milestone__item-title--top">
            <p>Didirikan pada tanggal 1 Januari 2012</p>
            <h2>2012</h2>
          </div>
          <Lottie
            animationData={preschool}
            className="milestone__animation"
            loop={true}
          />
        </div>
        <div className="milestone__item">
          <div className="milestone__item-title milestone__item-title--bottom">
            <p>Didirikan pada tanggal 1 Januari 2012</p>
            <h2>2012</h2>
          </div>
          <div className="milestone__checkpoint"></div>
        </div>
        <div className="milestone__item">
          <div className="milestone__item-title milestone__item-title--top">
            <h2>Dan selalu terus berdedikasi untuk anak</h2>
          </div>
          <Lottie
            animationData={daycare}
            className="milestone__animation"
            loop={true}
          />
        </div>
      </div>
    </section>
  );
};

export default Milestone;
