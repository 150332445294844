import React from "react";
import "./Hamburger.scss";

const Hamburger = ({ isChecked, setIsChecked }) => {
  return (
    <div className="hamburger">
      <input
        checked={isChecked}
        id="hamburger"
        onChange={(e) => setIsChecked(e.target.checked)}
        type="checkbox"
      />
      <label className="toggle" htmlFor="hamburger">
        <div className="bar bar--top"></div>
        <div className="bar bar--middle"></div>
        <div className="bar bar--bottom"></div>
      </label>
    </div>
  );
};

export default Hamburger;
