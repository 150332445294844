import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import React from "react";
import "./Commitment.scss";

const Commitment = () => {
  useGSAP(() => {
    gsap.utils.toArray(".commitment__container").forEach((section) => {
      let content = section.querySelector(".commitment__content");

      let tl = gsap.timeline({
        defaults: { ease: "none" },
        scrollTrigger: {
          anticipatePin: 1,
          end: () => "+=" + content.offsetWidth,
          scrub: 0.5,
          start: "center center",
          pin: true,
          trigger: section,
        },
      });

      tl.fromTo(
        section.querySelector(".commitment__container h1"),
        { xPercent: 0, x: 0 },
        { xPercent: -100 }
      );
    });
  });

  return (
    <section className="commitment__container">
      <div className="commitment__content">
        <h1>Lorem ipsum dolor sit.</h1>
      </div>
    </section>
  );
};

export default Commitment;
