import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import React, { useRef } from "react";
import "./Achievement.scss";

const Achievement = () => {
  const counterRef = useRef();

  useGSAP(
    () => {
      const counters = gsap.utils.toArray(".achievement__content h2");

      counters.forEach((counter) => {
        gsap.from(counter, {
          duration: 2,
          innerText: 0,
          scrollTrigger: {
            end: "bottom center",
            once: true,
            start: "top 90%",
            trigger: counter,
          },
          snap: {
            innerText: 1,
          },
        });
      });
    },
    { scope: counterRef }
  );

  return (
    <section className="achievement__container">
      <div className="achievement__content" ref={counterRef}>
        <div className="achievement__item">
          <h2>900</h2>
          <p>
            <b>Students</b>
          </p>
        </div>
        <div className="achievement__item">
          <h2>48</h2>
          <p>
            <b>Lecturers</b>
          </p>
        </div>
        <div className="achievement__item">
          <h2>200</h2>
          <p>
            {" "}
            <b> Graduated</b>
          </p>
        </div>
        <div className="achievement__item">
          <h2>10</h2>
          <p>
            <b>Years of Experience</b>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Achievement;
