import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import NotFound from "../components/NotFound";
import HomeLayout from "../layouts/HomeLayout";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Home from "../pages/Home";

const MyRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0 });

    return () => {};
  }, [location.pathname]);

  return (
    <Routes>
      <Route element={<HomeLayout />} path="/">
        <Route element={<About />} path="/about" />
        <Route element={<Contact />} path="/contact" />
        <Route element={<Home />} path="" />
      </Route>
      <Route element={<NotFound />} path="*" />
    </Routes>
  );
};

export default MyRoutes;
