import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import "./Gallery.scss";

const Gallery = () => {
  useGSAP(() => {
    gsap.from(".gallery__masonry img", {
      duration: 0.5,
      opacity: 0,
      scrollTrigger: {
        end: "bottom center",
        once: true,
        start: "top 90%",
        trigger: ".gallery__masonry",
      },
      stagger: 0.1,
      y: 80,
    });
  });

  return (
    <section className="gallery__container">
      <h1>Galeri</h1>
      <ResponsiveMasonry
        className="gallery__masonry"
        columnsCountBreakPoints={{ 320: 2, 720: 3, 1280: 4 }}
      >
        <Masonry gutter="8px">
          <img alt="" src="https://picsum.photos/200/300?image=1050" />
          <img alt="" src="https://picsum.photos/400/400?image=1039" />
          <img alt="" src="https://picsum.photos/400/400?image=1080" />
          <img alt="" src="https://picsum.photos/200/200?image=997" />
          <img alt="" src="https://picsum.photos/500/400?image=287" />
          <img alt="" src="https://picsum.photos/400/500?image=955" />
          <img alt="" src="https://picsum.photos/200/200?image=997" />
          <img alt="" src="https://picsum.photos/300/300?image=110" />
        </Masonry>
      </ResponsiveMasonry>
    </section>
  );
};

export default Gallery;
