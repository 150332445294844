import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import Lottie from "lottie-react";
import React from "react";
import clock from "../../../assets/jsons/clock.json";
import glasses from "../../../assets/jsons/glasses.json";
import starYellow from "../../../assets/svgs/star_yellow.svg";
import MyButton from "../../../components/MyButton/MyButton";
import "./CallToAction.scss";

const CallToAction = () => {
  useGSAP(() => {
    gsap.to(".cta__shape--star", {
      duration: 20,
      ease: "none",
      repeat: -1,
      rotation: -360,
    });
  });

  return (
    <section className="cta__container">
      <Lottie
        className="cta__animation cta__animation--clock"
        animationData={clock}
        loop={true}
      />
      <h1>CTA HEADER</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae,
        maxime laborum? Commodi, voluptatum dignissimos! Fuga itaque quia sequi
        odio pariatur rerum facere repellendus necessitatibus similique dolores
        libero magnam quae voluptates, veritatis ipsam possimus, blanditiis,
        eligendi neque consequuntur magni porro adipisci nisi? Reprehenderit
        neque adipisci molestias architecto voluptates tempora harum voluptas.
      </p>
      <div className="cta__button">
        <MyButton>Daftar Sekarang</MyButton>
      </div>
      <Lottie
        className="cta__animation cta__animation--glasses"
        animationData={glasses}
        loop={true}
      />
      <img alt="" className="cta__shape cta__shape--star" src={starYellow} />
    </section>
  );
};

export default CallToAction;
