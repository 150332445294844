import { useGSAP } from "@gsap/react";
import { LicenseInfo } from "@mui/x-license";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { BrowserRouter } from "react-router-dom";
import "swiper/css";
import "swiper/css/bundle";
import "./App.scss";
import MyRoutes from "./routes/MyRoutes";

function App() {
  gsap.registerPlugin(useGSAP);
  gsap.registerPlugin(ScrollToPlugin);
  gsap.registerPlugin(ScrollTrigger);
  LicenseInfo.setLicenseKey(`${process.env.REACT_APP_MUI_LICENSE_KEY}`);

  return (
    <BrowserRouter>
      <MyRoutes />
    </BrowserRouter>
  );
}

export default App;
