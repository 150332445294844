import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import Lottie from "lottie-react";
import React from "react";
import tagline from "../../../assets/jsons/tagline.json";
import "./Tagline.scss";

const Tagline = () => {
  useGSAP(() => {
    gsap.from(".tagline__animation", {
      duration: 2,
      ease: "elastic.out(2,1)",
      opacity: 0,
      scrollTrigger: {
        end: "bottom top",
        once: true,
        start: "top 70%",
        trigger: ".tagline__animation",
      },
      skewX: 20,
      x: -60,
    });
  });

  return (
    <section className="tagline__container" id="tagline">
      <div className="tagline__image">
        <Lottie
          className="tagline__animation"
          animationData={tagline}
          loop={true}
        />
      </div>
      <div className="tagline__content">
        <h1>Brand Tagline</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus, quidem
          libero delectus eveniet sed nostrum inventore ad qui doloribus
          deleniti ullam. Odio voluptatum quae, possimus eius reiciendis quas.
          Ipsa illum necessitatibus enim maxime, ratione eius quasi corporis sed
          quaerat officiis sint vero consectetur ut harum dolor perferendis,
          commodi, alias totam.
        </p>
      </div>
    </section>
  );
};

export default Tagline;
