import Lottie from "lottie-react";
import React, { useState } from "react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import catering from "../../../assets/jsons/catering.json";
import daycare from "../../../assets/jsons/daycare.json";
import homeEducare from "../../../assets/jsons/home_educare.json";
import learningCenter from "../../../assets/jsons/learning_center.json";
import preschool from "../../../assets/jsons/preschool.json";
import "./Service.scss";

const Service = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <section className="service__container">
      <h1>Our Services</h1>
      <Swiper
        breakpoints={{
          0: {
            slidesPerView: 1.2,
            spaceBetween: 10,
          },
          426: {
            slidesPerView: 1.7,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 2.3,
            spaceBetween: 10,
          },
          720: {
            slidesPerView: 3,
            spaceBetween: 15,
          },
          960: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
          1280: {
            slidesPerView: 5,
            spaceBetween: 30,
          },
        }}
        className="service__swiper service__swiper--thumb"
        freeMode={true}
        modules={[FreeMode, Navigation, Thumbs]}
        onSwiper={setThumbsSwiper}
        watchSlidesProgress={true}
      >
        <SwiperSlide className="service__swiper-slide service__swiper-slide--daycare">
          <h2>01</h2>
          <div className="service__swiper-content">
            <h3>Daycare</h3>
          </div>
        </SwiperSlide>
        <SwiperSlide className="service__swiper-slide service__swiper-slide--preschool">
          <h2>02</h2>
          <div className="service__swiper-content">
            <h3>Preschool</h3>
          </div>
        </SwiperSlide>
        <SwiperSlide className="service__swiper-slide service__swiper-slide--home-educare">
          <h2>03</h2>
          <div className="service__swiper-content">
            <h3>Home Educare</h3>
          </div>
        </SwiperSlide>
        <SwiperSlide className="service__swiper-slide service__swiper-slide--learning-center">
          <h2>04</h2>
          <div className="service__swiper-content">
            <h3>Learning Center</h3>
          </div>
        </SwiperSlide>
        <SwiperSlide className="service__swiper-slide service__swiper-slide--catering">
          <h2>05</h2>
          <div className="service__swiper-content">
            <h3>Catering</h3>
          </div>
        </SwiperSlide>
      </Swiper>
      <Swiper
        className="service__swiper service__swiper--main"
        modules={[FreeMode, Navigation, Thumbs]}
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
      >
        <SwiperSlide className="service__swiper-slide service__swiper-slide--daycare">
          <Lottie
            animationData={daycare}
            className="service__animation"
            loop={true}
          />
          <div className="service__swiper-content">
            <h2>Daycare</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia id,
              labore vel neque a iste eveniet explicabo ullam facilis dicta,
              voluptatibus consectetur, recusandae corporis reiciendis porro
              quis assumenda? Culpa, nam.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide className="service__swiper-slide service__swiper-slide--preschool">
          <Lottie
            animationData={preschool}
            className="service__animation"
            loop={true}
          />
          <div className="service__swiper-content">
            <h2>Preschool</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia id,
              labore vel neque a iste eveniet explicabo ullam facilis dicta,
              voluptatibus consectetur, recusandae corporis reiciendis porro
              quis assumenda? Culpa, nam.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide className="service__swiper-slide service__swiper-slide--home-educare">
          <Lottie
            animationData={homeEducare}
            className="service__animation"
            loop={true}
          />
          <div className="service__swiper-content">
            <h2>Home Educare</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia id,
              labore vel neque a iste eveniet explicabo ullam facilis dicta,
              voluptatibus consectetur, recusandae corporis reiciendis porro
              quis assumenda? Culpa, nam.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide className="service__swiper-slide service__swiper-slide--learning-center">
          <Lottie
            animationData={learningCenter}
            className="service__animation"
            loop={true}
          />
          <div className="service__swiper-content">
            <h2>Learning Center</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia id,
              labore vel neque a iste eveniet explicabo ullam facilis dicta,
              voluptatibus consectetur, recusandae corporis reiciendis porro
              quis assumenda? Culpa, nam.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide className="service__swiper-slide service__swiper-slide--catering">
          <Lottie
            animationData={catering}
            className="service__animation"
            loop={true}
          />
          <div className="service__swiper-content">
            <h2>Catering</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia id,
              labore vel neque a iste eveniet explicabo ullam facilis dicta,
              voluptatibus consectetur, recusandae corporis reiciendis porro
              quis assumenda? Culpa, nam.
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default Service;
